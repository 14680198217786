import { FC } from 'react'
import { ClientLogo } from '@/root/ui/shared'
import { FooterBottomContainer } from '@/root/components/bluewave/FooterBottomContainer/FooterBottomContainer'
import { useGetTranslations } from '@/root/shared-hooks'

export const FooterBottom: FC = () => {
  const { getTranslation } = useGetTranslations()
  const currentYear = new Date().getFullYear()

  return (
    <FooterBottomContainer>
      <ClientLogo clientName="bluewave" height={30} width={170} logo="footerLogo" />
      <p className="text-center text-sm text-bw-primary-veryLight">{`©${currentYear} ${getTranslation(
        'appPoweredByBluewave'
      )}`}</p>
    </FooterBottomContainer>
  )
}
