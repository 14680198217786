import Link from 'next/link'
interface MenuItemProps {
  text: string
  href: string
  type: 'top' | 'main'
}
const MenuItem = ({ text, href, type }: MenuItemProps) => {
  const style = {
    top: 'hidden md:block text-bw-primary-mediumLight font-bold text-lg',
    main: 'text-white font-bold text-xl',
  }

  return (
    <Link className={style[type]} href={href}>
      {text}
    </Link>
  )
}

export default MenuItem
