import { HeaderNavBarProps } from '@/root/shared-types'
import { MenuItem } from '@/root/ui/bluewave'
import { MainHeaderNavBarContainer } from '@/root/components/bluewave/MainHeaderNavBarContainer/MainHeaderNavBarContainer'
import { FC } from 'react'

export const MainHeaderNavBar: FC<HeaderNavBarProps> = ({ menuItems }) => {
  return (
    <MainHeaderNavBarContainer>
      {menuItems.map(({ id, subject, link }) => (
        <MenuItem href={link} text={subject} key={id} type={'main'} />
      ))}
    </MainHeaderNavBarContainer>
  )
}
