import { FC, useEffect, useState } from 'react'
import { MainHeaderNavBar } from '@/root/modules/bluewave/AppLayout/components/MainHeaderNavBar/MainHeaderNavBar'
import { TopHeaderMenu } from '@/root/modules/bluewave/AppLayout/components/TopHeaderMenu/TopHeaderMenu'
import { useCustomRouter, useMenuItems, useStaticContent } from '@/root/shared-hooks'
import { getMenuItemsByLine } from '@/root/modules/admaritime/AppLayout/helpers/getMenuItemsByLine'
import { MobileMenuContainer } from '@/root/components/bluewave/MobileMenuContainer/MobileMenuContainer'
import Link from 'next/link'

const Header: FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const { staticMenuItems } = useStaticContent()
  const { menuItems } = useMenuItems()
  const topHeaderMenuItems = getMenuItemsByLine(menuItems || staticMenuItems, 1)
  const mainHeaderMenuItems = getMenuItemsByLine(menuItems || staticMenuItems, 2)
  const router = useCustomRouter()

  useEffect(() => {
    const handleRouteChange = () => {
      setMobileMenuOpen(false)
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events, setMobileMenuOpen])

  return (
    <header>
      {mobileMenuOpen && (
        <MobileMenuContainer onClick={() => setMobileMenuOpen(false)}>
          {menuItems?.map(({ id, subject, link }) => (
            <Link href={link} key={id}>
              {subject}
            </Link>
          ))}
        </MobileMenuContainer>
      )}
      <TopHeaderMenu
        menuItems={topHeaderMenuItems}
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
      />
      <MainHeaderNavBar menuItems={mainHeaderMenuItems} />
    </header>
  )
}

export default Header
