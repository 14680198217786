import { ClientLogo } from '@/root/ui/shared'
import { HeaderNavBarProps, Locale } from '@/root/shared-types'
import { MenuItem } from '@/root/ui/bluewave'
import { TopMenuItemsContainer } from '@/root/components/bluewave/TopMenuItemsContainer/TopMenuItemsContainer'
import { LangSwitcher } from '@/root/modules/bluewave/AppLayout/components/LangSwitcher/LangSwitcher'

import Link from 'next/link'
import { Hamburger } from '@/root/ui/bluewave/Hamburger/Hamburger'
import { FC } from 'react'
import { useCustomRouter } from '@/root/shared-hooks'

interface TopHeaderMenuProps extends HeaderNavBarProps {
  mobileMenuOpen: boolean
  setMobileMenuOpen(state: boolean): void
}
export const TopHeaderMenu: FC<TopHeaderMenuProps> = ({ menuItems, mobileMenuOpen, setMobileMenuOpen }) => {
  const { locale, locales, asPath } = useCustomRouter()

  const displayLangSwitcher = locale !== undefined && locales !== undefined
  return (
    <>
      <nav className="mx-auto max-w-screen-xl">
        <div className="flex h-16 items-center justify-between px-2 xs:px-[40px] md:px-[40px] xl:px-[87px]">
          <Link href="/" className="flex h-full flex-col justify-center">
            <ClientLogo clientName="bluewave" width={170} height={30} logo="logo" />
          </Link>
          <TopMenuItemsContainer>
            {menuItems.map(({ id, subject, link }) => (
              <MenuItem href={link} text={subject} key={id} type={'top'} />
            ))}
            {displayLangSwitcher && <LangSwitcher locales={locales} locale={locale} asPath={asPath} />}
            {!mobileMenuOpen && <Hamburger onClick={() => setMobileMenuOpen(true)} />}
          </TopMenuItemsContainer>
        </div>
      </nav>
    </>
  )
}
