import React, { useState, FC } from 'react'
import cn from 'classnames'
import { RouteCheckboxWhiteIcon } from '../../shared/icons/RouteCheckboxWhiteIcon'

interface RouteCheckboxProps {
  label: string
  className?: string
}

const RouteCheckbox: FC<RouteCheckboxProps> = ({ label, className }) => {
  const [isChecked, setIsChecked] = useState(false)

  const handleCheckboxChange = () => {
    setIsChecked((prev) => !prev)
  }

  return (
    <div className="ml-[21px] flex items-start">
      <div
        className={cn(
          'w-5 h-5 border-2 border-secondary rounded-md mr-2 cursor-pointer flex items-center justify-center transition duration-700',
          {
            'bg-secondary shadow-lg': isChecked,
          }
        )}
        onClick={handleCheckboxChange}
      >
        {isChecked && <RouteCheckboxWhiteIcon />}
      </div>
      <p className={cn('pl-2 text-lg text-start font-bold text-primary-base', className)}>{label}</p>
    </div>
  )
}

export default RouteCheckbox
