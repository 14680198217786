import { FC, PropsWithChildren, ButtonHTMLAttributes } from 'react'
import cn from 'classnames'
import Icon from '@/root/ui/shared/Icon'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'success' | 'default'
  label?: string
  iconType?: string
  iconWidth?: number
}

const buttonVariants = {
  success: 'font-bold bg-secondary darken',
  default: 'bg-neutral-medium-lighter',
}

const Button: FC<PropsWithChildren<ButtonProps>> = ({
  iconType = '',
  iconWidth,
  children,
  variant = 'success',
  label,
  className,
  ...props
}) => {
  return (
    <button
      className={cn(
        'text-white border-none py-4 px-8 rounded-lg disabled:bg-adm-gray-450 text-center flex items-center gap-2',
        buttonVariants[variant],
        className
      )}
      {...props}
    >
      <div className="flex w-full items-center justify-center">
        <Icon alt={iconType} type={iconType} width={iconWidth || 42} />
        {label && <span className="flex items-baseline">{label}</span>}
        {children}
      </div>
    </button>
  )
}

export default Button
