import { CloseIcon } from '@/root/ui/shared/icons/CloseIcon'
import React, { FC, PropsWithChildren } from 'react'

interface MobileMenuContainerProps extends PropsWithChildren {
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

export const MobileMenuContainer: FC<MobileMenuContainerProps> = ({ children, onClick }) => (
  <div className="fixed z-10 flex size-full flex-col gap-5 overflow-auto bg-white px-4 pb-8 text-[28px] text-base-dark md:hidden">
    <div className=" flex h-16 justify-end">
      <button onClick={onClick}>
        <CloseIcon />
      </button>
    </div>
    {children}
  </div>
)
