import { FC, HTMLAttributes, ReactNode } from 'react'
import { Severity } from '@/root/shared-types/ui/ui-types'
import cn from 'classnames'

interface BannerProps extends HTMLAttributes<HTMLDivElement> {
  variant: Severity
  body?: ReactNode
  title?: string
}

const Banner: FC<BannerProps> = ({ body, title, variant, children, className }) => {
  return (
    <article
      className={cn(
        {
          'text-success-dark bg-success-very-light border border-success-dark rounded-md': variant === 'success',
        },
        {
          'text-complimentary-dark bg-warning-normal-lighter border border-complimentary rounded-md mb-0':
            variant === 'warning',
        },
        {
          'bg-error-lightest font-normal rounded-md text-center': variant === 'error',
        },
        { 'text-success-dark': variant === 'neutral' },
        'py-4 px-7 text-center font-bold',
        className
      )}
    >
      {Boolean(title) && (
        <h2
          className={cn(
            'mb - 1',
            { 'text-2xl': variant == 'success' },
            { 'text-2xl': variant === 'warning' },
            { 'text-xl font-normal uppercase text-error-dark': variant === 'error' },
            { 'text-3xl': variant === 'neutral' }
          )}
        >
          {title}
        </h2>
      )}
      <p className="pt-4 text-lg font-normal">{body}</p>
      {children}
    </article>
  )
}

export default Banner
