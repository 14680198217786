import { FC, PropsWithChildren } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'

interface DropdownContainerProps extends PropsWithChildren {
  onClose(): void
}
export const DropdownContainer: FC<DropdownContainerProps> = ({ children, onClose }) => (
  <OutsideClickHandler onOutsideClick={onClose}>
    <div
      className="absolute z-10 mt-4 w-fit flex-col space-y-3 rounded-md bg-white p-2 shadow-lg ring-1 ring-black ring-opacity-50 focus:outline-none md:w-32 md:p-3"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
    >
      {children}
    </div>
  </OutsideClickHandler>
)
