import { FC, ButtonHTMLAttributes } from 'react'

const ButtonForInput: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({ onClick, children, className, disabled }) => (
  <button
    onClick={onClick}
    className={`flex h-9 min-w-[110px] max-w-[200px] items-center gap-3 rounded rounded-l-none bg-tertiary px-2 font-medium text-white ${className} disabled:bg-neutral-medium-lighter`}
    disabled={disabled}
  >
    {children}
  </button>
)

export default ButtonForInput
