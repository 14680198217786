import { FC } from 'react'
import { UpperFooterItem } from '@/root/shared-types'
import FooterTopItem from '@/root/modules/bluewave/AppLayout/components/FooterTopItem/FooterTopItem'
import { useConfig } from '@/root/shared-hooks'

interface FooterTopProps {
  footerItems: UpperFooterItem[]
}

export const FooterTop: FC<FooterTopProps> = ({ footerItems }) => {
  const { oldBoraOrigin } = useConfig()
  return (
    <div className="bg-primary-base text-white ">
      <div className="mx-auto grid max-w-screen-xl grid-cols-2 gap-5 px-6 py-[50px] md:flex md:flex-wrap md:justify-around ">
        {footerItems.map(({ title, content }) => (
          <FooterTopItem title={title} content={content} key={title} oldBoraOrigin={oldBoraOrigin} />
        ))}
      </div>
    </div>
  )
}
