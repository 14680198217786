import React, { FC, PropsWithChildren } from 'react'
import { ChevronDownIcon } from '../../shared/icons/ChevronDownIcon'

interface DropdownButtonProps extends PropsWithChildren {
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

const DropdownButton: FC<DropdownButtonProps> = ({ children, onClick }) => {
  return (
    <button
      className="text-bw-primary-mediumLight flex items-center justify-center gap-2 text-lg font-bold "
      onClick={onClick}
    >
      {children}
      <ChevronDownIcon />
    </button>
  )
}

export default DropdownButton
